import React from 'react';

const Zinkerz = () => {
    return (
        <div>
            Desarrollo y mantenimiento de plataformas e-learning orientadas a las pruebas de admisión universitarias en USA. Se cumplía rol de Desarrollador, DevOps, asi como también participar de reuniones con el equipo de Diseño y el SH para la planificación de nuevas funcionalidades.
        </div>
    );
};

export default Zinkerz;